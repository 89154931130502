<template>
  <div class="admin_layouts">
    <a-config-provider :locale="locale">
      <div class="bo_content">
        <AdminSider />
        <div id="page_router_view">
          <AdminHeader />
          <AdminPageTitle />
          <div class="page_body_area_person">
            <a-back-top :target="target" />
            <div :class="[noWhiteBg ? '' : 'really_content_area']">
              <transition mode="out-in">
                <router-view />
              </transition>
            </div>
          </div>
        </div>
      </div>
    </a-config-provider>
  </div>
</template>

<script>
import AdminHeader from "./assembly/adminHeader";
import AdminSider from "./assembly/adminSider";
import AdminPageTitle from "./assembly/adminPageTitle";
import zhCN from "ant-design-vue/lib/locale-provider/zh_CN";
export default {
  name: "adminLayouts",
  data() {
    return {
      locale: zhCN,
      target: () => {
        return document.querySelector(".page_body_area_person");
      },
    };
  },
  created() {},
  computed: {
    noWhiteBg() {
      return this.$route.meta.noWhiteBg ? this.$route.meta.noWhiteBg : false;
    },
  },
  components: {
    AdminHeader,
    AdminSider,
    AdminPageTitle,
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.admin_layouts {
  display: flex;
  flex-direction: column;
  height: 100%;
  .bo_content {
    flex-grow: 2;
    display: flex;
    overflow-y: auto;
    #page_router_view {
      flex-grow: 2;
      display: flex;
      flex-direction: column;
      overflow-y: hidden;

      .page_body_area_person {
        flex-grow: 2;
        padding: 20px;
        overflow-y: auto;
        background: rgba(240, 242, 245, 1);
      }
    }
  }
}
.really_content_area {
  padding: 20px;
  background: #fff;
}
.v-enter {
  opacity: 0;
  transform: translateX(-150px);
}
.v-leave-to {
  opacity: 0;
  transform: translateX(150px);
}
.v-enter-active,
.v-leave-active {
  transition: all 0.5s ease;
}
</style>
