import { adminLayouts } from "@/layouts";

export const addressBookRouter = [
  {
    path: "/addressBook",
    name: "/addressBook",
    redirect: "/addressBookList",
    component: adminLayouts,
    meta: {
      pageTitle: "通讯录",
    },
    children: [
      {
        path: "/addressBookList",
        name: "addressBookList",
        component: () => import("@/views/addressBook/addressBookList"),
        meta: {
          pageTitle: "通讯录",
          noWhiteBg: true,
        },
      },
    ],
  },
];
