import router from "./index";
router.beforeEach((to, from, next) => {
  //   let path = to.path;
  //   if (path == "/login") {
  //     if (
  //       localStorage.getItem("xxxxxxxxxxxxxx") ||
  //       localStorage.getItem("xxxxxxxxxxxxxx")
  //     ) {
  //       next("/element");
  //     } else {
  //       next();
  //     }
  //   }
  next();
});
