<template>
  <div id="app">
    <a-config-provider :locale="locale">
      <transition name="sidebarLogoFade">
        <router-view />
      </transition>
    </a-config-provider>
  </div>
</template>

<script>
import { mapState } from "vuex";
import zhCN from "ant-design-vue/lib/locale-provider/zh_CN";
import router from "@/router";
export default {
  name: "app",
  data() {
    return {
      locale: zhCN,
    };
  },
  components: {},
  computed: {
    ...mapState(["ycIsLoginBool"]),
  },
  created() {
    if (sessionStorage.getItem("love_tabls_session_data_vuezx")) {
      this.$store.replaceState(
        Object.assign(
          {},
          this.$store.state,
          JSON.parse(sessionStorage.getItem("love_tabls_session_data_vuezx"))
        )
      );
    }
    if(router.history.current.query.zj_unicom_token){
        localStorage.setItem('jx_token1', router.history.current.query.zj_unicom_token)
    }
    window.addEventListener("pagehide", () => {
      sessionStorage.setItem(
        "love_tabls_session_data_vuezx",
        JSON.stringify(this.$store.state)
      );
    });
  },
};
</script>
<style lang="less" scoped>
.sidebarLogoFade-enter-active {
  transition: opacity 0.5s;
}
.sidebarLogoFade-enter,
.sidebarLogoFade-leave-to {
  opacity: 0;
}
</style>
