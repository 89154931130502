import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    //菜单id存储
    nowSilderId: 1,
    openKeyId: "",
    //页面标题
    pageTitle: [],
  },
  mutations: {
    //存储当前的nav
    changeSilderClick(state, data) {
      state.nowSilderId = data;
    },
    //存储当前nav说在的list Keys
    changeOpenKeysId(state, data) {
      state.openKeyId = data;
    },
    setPageArray(state, val) {
      state.pageTitle = val.map((item) => {
        return item.meta.pageTitle;
      });
    },
    modifyPageT(state, val) {
      state.pageTitle.splice(state.pageTitle.length - 1, 1, val);
    },
  },
  actions: {},
  modules: {},
});
