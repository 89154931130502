var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"admin_sider"},[_c('div',{staticClass:"scroll_div"},[_c('a-menu',{staticClass:"amenu",attrs:{"theme":"dark","selectedKeys":_vm.defaultSelectedKeys,"openKeys":_vm.openKeys,"mode":"inline"},on:{"openChange":_vm.onOpenChange}},[_vm._l((_vm.silderMenu),function(item){return [(item.childList.length <= 0)?_c('a-menu-item',{key:item.t.menuId,on:{"click":function($event){_vm.goto(item.t.menuUrl),
              _vm.getPushTips(item.t.menuName, item.menuUrl, item.t.menuId)}}},[_c('span',[_vm._v(_vm._s(item.t.menuName))])]):_c('a-sub-menu',{key:item.t.menuId},[_c('span',{attrs:{"slot":"title"},slot:"title"},[_c('span',[_vm._v(_vm._s(item.t.menuName))])]),_vm._l((item.childList),function(itemItem){return _c('a-menu-item',{key:itemItem.t.menuId,on:{"click":function($event){_vm.goto(itemItem.t.menuUrl),
                _vm.getPushTips(
                  itemItem.t.menuName,
                  itemItem.t.menuUrl,
                  itemItem.t.menuId
                )}}},[_vm._v(_vm._s(itemItem.t.menuName))])})],2)]})],2)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }