import { adminLayouts } from "@/layouts";

export const threeProofingRouter = [
  {
    path: "/threeProofing",
    name: "/threeProofing",
    redirect: "/threeProofingList",
    component: adminLayouts,
    meta: {
      pageTitle: "学校三防",
    },
    children: [
      {
        path: "/threeProofingList",
        name: "threeProofingList",
        component: () => import("@/views/threeProofing/threeProofingList"),
        meta: {
          pageTitle: "学校三防",
          noWhiteBg: true,
        },
      },
    ],
  },
];
